@import 'variables';

html {
  scrollbar-gutter: stable;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

::-ms-reveal {
  display: none;
}

body {
  font-family: Roboto, sans-serif;
}

a,
body {
  color: $black;
}

main.page {
  min-height: 387px;
  @media (min-width: $screen-md) {
    min-height: 375px;
  }
}

.link {
  color: $guidance-500-core;
  font-weight: bold;
  text-decoration: none;

  &:visited {
    color: $support-3-600;
  }

  &:hover {
    text-decoration: underline;
  }
}
