@import 'src/variables';
@import 'src/app/component-library-wave/designtokens';

.item {
  padding: 4 * $spacing;
  margin-bottom: 2 * $spacing;
  border: solid 1px $secondary200;
  border-radius: $border-radius;
  list-style: none;

  @media (min-width: $screen-md) {
    padding: (4 * $spacing) (6 * $spacing);
    margin-bottom: 4 * $spacing;
  }

  &:last-child {
    margin-bottom: 6 * $spacing;
  }

  .toggleContainer {
    margin: 0 (-2 * $spacing) (2 * $spacing);

    label {
      font-weight: bold;
      font-size: #{calc(18 / $base-font-size)}rem;

      @media (min-width: $screen-md) {
        font-size: #{calc(20 / $base-font-size)}rem;
      }
    }
  }

  .description {
    margin-bottom: 2 * $spacing;
    line-height: #{calc(21 / $base-font-size)}rem;

    @media (min-width: $screen-md) {
      line-height: #{calc(25 / $base-font-size)}rem;
    }
  }

  .frequencyUser {
    margin-top: 2 * $spacing;
    background-color: $guidance-050-tint;
    border-radius: $border-radius;
    padding: 2 * $spacing;
    line-height: #{calc(21 / $base-font-size)}rem;

    @media (min-width: $screen-md) {
      line-height: #{calc(25 / $base-font-size)}rem;
    }
  }
}
