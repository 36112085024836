@import 'src/variables';

.group {
  .heading {
    margin-bottom: 2 * $spacing;
    text-align: center;
  }
}

.subscriptionItems {
  padding: 0;
}
