@import 'variables';

.consentContainer {
  margin: 0 $mobile-edge;

  .consentHeader {
    margin-bottom: 2rem;
  }

  .consentParagraph {
    margin-bottom: 1.5rem;
  }

  .radioContainer {
    border: 1px solid $secondary200;
    border-radius: $border-radius;
    padding: 17.5px 18px;
    margin-bottom: 2rem;

    .radioRow {
      margin-bottom: 21px;

      &:last-child {
        margin-bottom: 0;
      }

      .radioInput {
        margin-right: $spacing;
        cursor: pointer;
      }

      .radioLabel {
        cursor: pointer;
      }
    }

    .yourSelectionDescription {
      margin-bottom: 0;
      text-align: center;
    }
  }

  .desktopCenterContainer {
    @media (min-width: $screen-md) {
      text-align: center;
    }

    .confirmButton {
      width: 100%;
      @media (min-width: $screen-md) {
        width: inherit;
      }
      margin-bottom: 4.5rem;
    }
  }
}
