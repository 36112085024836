@import 'src/variables';

.container {
  padding: 0 $mobile-edge;
  @media (min-width: $screen-md) {
    margin: 0 auto ($spacing * 7);
    max-width: $grid-wide;
  }

  .heading {
    margin-bottom: 5 * $spacing;
    @media (min-width: $screen-md) {
      margin-bottom: 7 * $spacing;
    }
  }

  .question {
    margin-bottom: 2 * $spacing;
  }

  .answer {
    margin-bottom: 7 * $spacing;
  }

  .myprofilelink {
    color: $link;
    font-weight: bold;
  }

  .options {
    margin-bottom: 9 * $spacing;
  }
}
